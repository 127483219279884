@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;  
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #6d6d6d;
}