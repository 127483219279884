.fade-animation img {
    animation: fadeAnimation 1s ease-out;
}

@keyframes fadeAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
