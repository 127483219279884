.profilepic {
    -webkit-clip-path: polygon(0 8%, 94% 1%, 100% 83%, 14% 100%);
    clip-path: polygon(0 8%, 94% 1%, 100% 83%, 14% 100%);
    border-radius: 5px;
}

.clients {
    img {
        filter: grayscale(100%);
    }

    img:hover {
        filter: none;
        cursor: pointer;
    }
}

.parallax {
    // background-image: url("img_parallax.jpg");
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (max-device-width: 1366px) {
    .parallax {
        background-attachment: scroll;
    }
}

.scroll-downs {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    width: 34px;
    height: 55px;
}

.mousey {
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid #f5f5f5;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
}

.scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #f5f5f5;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    animation-iteration-count: infinite;
}

@keyframes scroll {
    0% {
        opacity: 0;
    }
    10% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(15px);
        opacity: 0;
    }
}
